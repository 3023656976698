<template>
  <div>
    <div
      class="masonry-container"
      v-masonry="containerId"
      transition-duration="0.3s"
      column-width=".grid-sizer"
      item-selector=".masonry-item"
    >
      <div class="grid-sizer"></div>
      <router-link
        v-masonry-tile
        :to="'/article/'+item.url"
        class="masonry-item"
        :class="
          'grid-item--width' + item.width + ' grid-item--height' + item.height
        "
        v-for="(item, key) in masonryItems"
        :key="key"
      >
        <img :src="$root.uploadsurl + item.thumb.path" :alt="item.title" />
        <div
          class="masonry-overlay masonry-overlay-main"
          v-if="item.layout == 0"
        >
          <h3>{{ item.title }}</h3>
          <p>{{ item.desc_short }}</p>
        </div>
        <div class="masonry-overlay" v-if="item.layout == 1">
          <h3>{{ item.title }}</h3>
        </div>
        <div class="masonry-overlay" v-if="item.layout == 2"></div>
        <div class="masonry-overlay" v-if="item.layout == 3">
          <span>+</span>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Masonrylist",
  props: {
    masonryItems: Array,
  },
  computed: {
    itemClasses: function (w, h) {
      return "grid-item--width" + w + " grid-item--height" + h;
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_imports.scss";
@import "@/assets/scss/_variables.scss";

$columnwidth: 100%;
$columnheight: 100%;

.masonry-container {
  max-width: 960px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 10000px;
}
.grid-sizer {
  width: $columnwidth/3;
}
.grid-item--width1 {
  width: $columnwidth/3;
}
.grid-item--width2 {
  width: $columnwidth * 2/3;
}
.grid-item--width3 {
  width: $columnwidth;
}
.grid-item--height1 {
  padding-bottom: $columnheight/3;
}
.grid-item--height2 {
  padding-bottom: $columnheight * 2/3;
}
.grid-item--height3 {
  padding-bottom: $columnheight;
}

.masonry-item {
  overflow: hidden;
}
.masonry-item:hover {
  background-color: $lightgold;
}
.masonry-item img {
  max-width: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 1s;
}
.masonry-item:hover img {
  max-width: 104%;
  width: 104%;
  top: -2%;
  left: -2%;
  opacity: 0.8;
  transition: all 0.3s;
}
.masonry-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  padding: 20px;
  text-align: center;
}
.masonry-overlay h3 {
  font-family: $serif-font;
  color: $white;
  font-size: 1.8rem;
  font-weight: 600;
}
.masonry-overlay.masonry-overlay-main h3 {
  color: $gold;
  font-size: 1.6rem;
}
.masonry-overlay p {
  font-family: $sans-serif-font;
  color: $white;
  font-size: 1rem;
  font-weight: 400;
}
.masonry-overlay span {
  display: block;
  font-family: $sans-serif-font;
  color: $white;
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 600;
  text-align: right;
}
</style>
