<template>
  <div class="fullbanner">
    <img :src="src" :alt="alt">
  </div>
</template>

<script>
export default {
  name: "fullbanner",
  props: {
    src: String,
    alt: String,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_imports.scss";
@import "@/assets/scss/_variables.scss";
.fullbanner {
  width: 100%;
  img {
    width: 100%;
    height: auto;
  }
}
</style>
