<template>
  <div>
    <div
      class="masonry-container"
      v-masonry="containerId"
      transition-duration="0.3s"
      column-width=".grid-sizer"
      item-selector=".masonry-item"
    >
      <div class="grid-sizer"></div>
      <a
        v-masonry-tile
        @click=" (item.thumb && !item.textcontent) ? openSlider(item._id) : ''"
        class="masonry-item"
        :class="
          'grid-item--width' + item.width + ' grid-item--height' + item.height + ((item.thumb && !item.textcontent) ? ' image' : '')
        "
        v-for="(item, key) in masonryItems"
        :key="key"
      >
        <div class="masonry-text-content" v-html="item.textcontent" v-if="item.textcontent"></div>
        <div class="masonry-item-image">
          <img :src="$root.uploadsurl + item.thumb.path" :alt="item.title" v-if="item.thumb" />
        </div>
        <div
          class="masonry-overlay masonry-overlay-main"
          v-if="item.layout == 0 && item.thumb && !item.textcontent"
        >
          <h3>{{ item.title }}</h3>
          <p>{{ item.desc_short }}</p>
        </div>
        <div class="masonry-overlay" v-if="item.layout == 1 && item.thumb && !item.textcontent">
          <h3>{{ item.title }}</h3>
        </div>
        <div class="masonry-overlay" v-if="item.layout == 2 && item.thumb && !item.textcontent"></div>
        <div class="masonry-overlay" v-if="item.layout == 3 && item.thumb && !item.textcontent">
          <span>+</span>
        </div>
      </a>
    </div>
    <div class="slider" v-if="slidervisible">
      <div class="slider-close" @click="closeSlider()">×</div>
      <div class="slider-strip" :class="{'left-transition': transitiontype == 0, 'right-transition': transitiontype == 1}">
        <!-- <transition-group name="slider-list" tag="div"> -->
          <div class="slider-item" v-for="(item, key) in sliderItems" :key="key" :class="{'visible': sliderkey == item._id, 'entering': sliderentering == item._id, 'discarded': sliderdiscarded == item._id}" :data-index="item._id">
            <img :src="$root.uploadsurl + item.image.path" :alt="item.title" v-if="item.image" />
            <b>{{item.title}}</b>
            <div v-html="item.subtitle"></div>
          </div>
        <!-- </transition-group> -->
      </div>
      <div class="slider-nav">
        <div class="slider-prev" @click="prevSlider()"><span>&langle;</span></div>
        <div class="slider-next" @click="nextSlider()"><span>&rangle;</span></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Masonrylist",
  props: {
    masonryItems: Array,
  },
  data: function() {
    return {
      slidervisible: false,
      sliderindex: 0,
      sliderkey: 0,
      sliderentering: -1,
      sliderdiscarded: -1,
      transitiontype: 0,
      intransition: false
    }
  },
  computed: {
    sliderItems() {
      let filtered = Array.from(this.masonryItems).filter(function (item) {
          return item.image
      });
      return filtered
    }
  },
  methods: {
    closeSlider() {
      this.slidervisible = false
    },
    openSlider(key) {
      this.slidervisible = true
      this.sliderkey = key
      this.sliderindex = this.sliderItems.map(function(e) { return e._id; }).indexOf(this.sliderkey);
    },
    nextSlider() {
      if(!this.intransition) {
        this.intransition = true
        if(this.sliderindex < this.sliderItems.length - 1) this.sliderindex++
        else this.sliderindex = 0
        
        this.transitiontype = 0

        this.sliderdiscarded = this.sliderkey

        this.sliderentering = this.sliderItems[this.sliderindex]._id

        setTimeout(() => {this.sliderkey = this.sliderentering}, 10)

        setTimeout(() => {this.sliderdiscarded = -1}, 400)
        setTimeout(() => {this.sliderentering = -1}, 400)
        setTimeout(() => {this.intransition = false}, 400)
      }
    },
    prevSlider() {
      if(!this.intransition) {
        this.intransition = true
        if(this.sliderindex > 0) this.sliderindex--
        else this.sliderindex = this.sliderItems.length - 1
        
        this.transitiontype = 1

        this.sliderdiscarded = this.sliderkey

        this.sliderentering = this.sliderItems[this.sliderindex]._id
        
        setTimeout(() => {this.sliderkey = this.sliderentering}, 10)

        setTimeout(() => {this.sliderdiscarded = -1}, 400)
        setTimeout(() => {this.sliderentering = -1}, 400)
        setTimeout(() => {this.intransition = false}, 400)
      }
    }
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_imports.scss";
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_media.scss";

$columnwidth: 100%;
$columnheight: 100%;

.masonry-container {
  max-width: 960px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 10000px;
}
.grid-sizer {
  width: $columnwidth/3;
}
.grid-item--width1 {
  width: $columnwidth/3;
}
.grid-item--width2 {
  width: $columnwidth * 2/3;
}
.grid-item--width3 {
  width: $columnwidth;
}
.grid-item--height1 {
  padding-bottom: $columnheight/3;
}
.grid-item--height2 {
  padding-bottom: $columnheight * 2/3;
}
.grid-item--height3 {
  padding-bottom: $columnheight;
}

.masonry-item {
  overflow: visible;
  cursor: default;
  .masonry-item-image {
    max-width: 100%;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 1s;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 100%;
      width: 100%;
    }
  }
  .masonry-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    padding: 20px;
    text-align: center;
    h3 {
      font-family: $serif-font;
      color: $white;
      font-size: 1.8rem;
      font-weight: 600;
    }
    p {
      font-family: $sans-serif-font;
      color: $white;
      font-size: 1rem;
      font-weight: 400;
    }
    span {
      display: block;
      font-family: $sans-serif-font;
      color: $white;
      font-size: 2rem;
      line-height: 2rem;
      font-weight: 600;
      text-align: right;
    }
    &.masonry-overlay-main h3 {
      color: $gold;
      font-size: 1.6rem;
    }
  }
  .masonry-text-content {
    max-width: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px;
    color: $white;
    transition: all .4s;
    font-size: 1rem;
    max-height: 100%;
    overflow: hidden;
  }
  &.image:hover {
    cursor: pointer;
    color: $lightgold;
    .masonry-item-image {
      opacity: .7;
      transition: all 0.3s;
      img {
      }
    }
    .masonry-text-content {
      color: $lightgold;
    }
  }
}

.slider {
  position: fixed;
  z-index: 100;
  top: 68px;
  background-color: $black;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .slider-close {
    position: absolute;
    display: block;
    width: 69px;
    height: 69px;
    top: -68px;
    right: 0;
    background-color: $black;
    text-align: center;
    line-height: 69px;
    font-size: 60px;
    color: $white;
    &:hover {
      cursor: pointer;
      color: $lightgold;
    }
  }
  .slider-strip {
    max-height: 100%;
    height: 100%;
    width: 100%;
    position: relative;
    .slider-item {
      width: 100%;
      padding: 0 20%;
      max-height: 100%;
      height: 100%;
      display: none;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      overflow-y: auto;
      padding: 70px 20% 140px 20%;
      &.entering,
      &.visible,
      &.discarded {
        display: flex;
      }
      img {
        max-width: 100%;
        height: auto;
        display: block;
      }
      b {
        font-size: 40px;
        line-height: 44px;
        font-weight: bold;
        margin-top: 16px;
        margin-bottom: 12px;
        padding: 0 10px;
        text-align: center;
        flex-shrink: 0;
      }
      > div {
        font-size: 28px;
        line-height: 32px;
        font-weight: normal;
        padding: 0 10px;
        text-align: center;
        flex-shrink: 0;
        ::v-deep {
          p {
            font-size: 28px;
            line-height: 32px;
            font-weight: normal;
            margin-bottom: 0;
          }
        }
      }
    }
    &.left-transition {
      .slider-item {
        transition: left .4s;
        left: 101%;
        &.entering {
          left: 101%;
        }
        &.visible {
          left: 0;
        }
        &.discarded {
          left: -101%;
        }
      }
    }
    &.right-transition {
      .slider-item {
        transition: left .4s;
        left: -101%;
        &.entering {
          left: -101%;
        }
        &.visible {
          left: 0;
        }
        &.discarded {
          left: 101%;
        }
      }
    }
  }
  .slider-nav {
    position: absolute;
    left: 0;
    width: 100%;
    padding: 0 5%;
    top: 50%;
    margin-top: -100px;
    display: flex;
    justify-content: space-between;
    .slider-prev,
    .slider-next {
      width: 50px;
      height: 50px;
      text-align: center;
      span {
        display: block;
        transform: scaleX(2);
        line-height: 50px;
        font-size: 40px;
        color: $white;
      }
      &:hover {
        cursor: pointer;
        span {
          color: $lightgold;
        }
      }
    }
    .slider-prev {

    }
    .slider-next {

    }
  }
}
@media #{$phone} {
  .slider {
    .slider-close {
    }
    .slider-strip {
      .slider-item {
        b {
          font-size: 20px;
          line-height: 24px;
          margin-top: 16px;
          margin-bottom: 12px;
        }
        > div {
          font-size: 14px;
          line-height: 18px;
          ::v-deep {
            p {
              font-size: 14px;
              line-height: 18px;
            }
          }
        }

      }

    }
    .slider-nav {
      .slider-prev,
      .slider-next {
        width: 30px;
        height: 30px;
        span {
          line-height: 30px;
          font-size: 30px;
        }
      }
    }
  }
}
</style>
