import { createStore } from "vuex";
// import axios from 'axios'

export default createStore({
  state: {
    aboutData: {
      ProfilePicture: {
        path: '/images/about/about.jpg'
      },
      Greeting: 'Hello,',
      Text: 'I\'m Franz — scientific illustrator, graphic designer, and invertebrate shitposter.',
    },
    wordingData: {
      GalleryNaming: 'Gallery',
      GalleryNamingPlural: 'Galleries',
      ArticleNaming: 'Article',
      ArticleNamingPlural: 'Articles',
      ImageNaming: 'Image',
      ImageNamingPlural: 'Images',
    },
    contactData: {
      Email: '',
      Twitter: '',
      Instagram: '',
      Contactme: '',
      Supportme: '',
    },
    galleries: null,
    articles: null,
  },
  mutations: {
    setGalleries (state, data) {
      state.galleries = data
    },
    setArticles (state, data) {
      state.articles = data
    },
    setAboutdata (state, data) {
      state.aboutData = data
    },
    setWordingdata (state, data) {
      state.wordingData = data
    },
    setContactdata (state, data) {
      state.contactData = data
    }
  },
  actions: {
    setGalleries (context, data) {
      context.commit('setGalleries', data)
    },
    setArticles (context, data) {
      context.commit('setArticles', data)
    },
    setAboutdata (context, data) {
      context.commit('setAboutdata', data)
    },
    setWordingdata (context, data) {
      context.commit('setWordingdata', data)
    },
    setContactdata (context, data) {
      context.commit('setContactdata', data)
    }
  },
  modules: {},
});