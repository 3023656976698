<template>
  <div class="gallerynamebox">
    <router-link class="galleryname" :to="galleryUrl" v-if="galleryUrl">
      {{ galleryName }}<b>{{ galleryNameBold }}</b>
    </router-link>
    <span class="galleryname" v-else>
      {{ galleryName }}<b>{{ galleryNameBold }}</b>
    </span>
  </div>
</template>

<script>
export default {
  name: "galleryNameBox",
  props: {
    galleryName: String,
    galleryNameBold: String,
    galleryUrl: String,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_imports.scss";
@import "@/assets/scss/_variables.scss";
.gallerynamebox {
  text-align: center;
  .galleryname {
    display: block;
    font-family: $serif-font;
    padding: 0 8vw;
    position: relative;
    color: $gold;
    font-size: 1.8rem;
    line-height: 1.2;
    font-weight: 500;
    margin-bottom: 5rem;
  }
  a:hover {
    text-decoration: none;
    opacity: .8;
    cursor: pointer;
  }
  .galleryname b {
    display: block;
    color: $white;
    font-size: 3.2rem;
    font-weight: 500;
  }
  @mixin abspos {
    display: block;
    position: absolute;
    top: 0;
    font-family: $serif-font;
    font-size: 10rem;
    font-weight: 300;
    line-height: 6rem;
  }
  .galleryname:before {
    content: "{";
    left: 0;
    @include abspos();
  }
  .galleryname:after {
    content: "}";
    right: 0;
    @include abspos();
  }
}
</style>
