<template>
  <div class="page" :class="{hasmenubar: hasmenubar}">
    <Menubar v-if="hasmenubar" :pageName="pageName" :galleries="galleries"></Menubar>
    <slot/>
    <Navtree
      :invitationText="invitationText"
      :galleryName="galleryOtherName"
      :galleryNameBold="galleryOtherNameBold"
      :galleryUrl="galleryOtherUrl"
      :galleries="galleries"
    ></Navtree>
  </div>
</template>

<script>
import Menubar from "@/components/Menubar.vue";
import Navtree from "@/components/Navtree.vue";

export default {
  components: {
    Menubar,
    Navtree,
  },
  props: {
    hasmenubar: Boolean
  },
  data: function () {
    return {
      apikey: process.env.VUE_APP_PUBLIC_API,
      siteurl: process.env.VUE_APP_API_URL,
      pageName: "",
      galleryOtherName: "",
      galleryOtherNameBold: "Biodiversity",
      galleryOtherUrl: null,
      invitationText: "START EXPLORING AT",
      galleries: this.$store.state.galleries,
      articles: this.$store.state.articles,
      wordingData: this.$store.state.wordingData
    };
  },
  methods: {
    async fetch() {
      window.scrollTo({
        top: 0
      })
      this.galleryOtherNameBold = this.galleries[0].title
      this.galleryOtherUrl = '/gallery/' + this.galleries[0].url
      this.galleryOtherName = "THE " + this.wordingData.GalleryNaming.toUpperCase() + " OF"
      let url = this.$route
      if(url.name == "Gallery") {
        let galleryurl = this.$route.params.gallery_url;
        if (galleryurl == 'main' || !galleryurl) {
          this.invitationText = 'START EXPLORING AT'
          this.pageName = 'main '+ this.wordingData.GalleryNaming;
        } else {
          let findgallery = this.galleries.filter(function (e) {
            return e.url == galleryurl;
          });
          let galleryIndex = this.galleries.indexOf(findgallery[0])
          this.pageName = findgallery[0].title + ' gallery';
          this.invitationText = 'CONTINUE EXPLORING AT'
          if(this.galleries[galleryIndex+1] && this.galleries[galleryIndex+1].title != 'Main') {
            this.galleryOtherNameBold = this.galleries[galleryIndex+1].title
            this.galleryOtherUrl = '/gallery/' + this.galleries[galleryIndex+1].url
          } else {
            this.galleryOtherNameBold = this.galleries[0].title
            this.galleryOtherUrl = '/gallery/' + this.galleries[0].url
          }
        }
      } else if(url.name == "Article")  {
        let articleurl = this.$route.params.article_url;
        let findarticle = this.articles.filter(function (e) {
          return e.url == articleurl;
        });
        // console.log(findarticle)
        let findgallery = this.galleries.filter(function (e) {
          return e._id == findarticle[0].gallery._id;
        });
        // console.log(findgallery)
        this.pageName = findarticle[0].title;
        this.invitationText = 'SEE MORE ABOUT THIS AT'
        this.galleryOtherNameBold = findarticle[0].gallery.display
        this.galleryOtherUrl = '/gallery/' + findgallery[0].url
      } else {
        this.pageName = 'main '+ this.wordingData.GalleryNaming;
      }
    },
  },
  mounted() {
    this.fetch();
  },
  watch: {
    $route: "fetch",
  },
}
</script>

<style scoped lang="scss">
.page {
  min-height: 100%;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &.hasmenubar {
    padding-top: 69px;
  }
}
</style>
