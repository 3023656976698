import { createRouter, createWebHistory } from "vue-router";
// import Home from "../views/Home.vue";
import Gallery from "../views/Gallery.vue";
import Article from "../views/Article.vue";
import ErrorPage from "../views/ErrorPage.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Gallery,
    props: { welcomeOverlay: true }
  },
  {
    path: "/gallery/:gallery_url?",
    name: "Gallery",
    component: Gallery,
    props: { welcomeOverlay: false }
  },
  {
    path: "/article/:article_url?",
    name: "Article",
    component: Article
  },
  {
      path: '/:pathMatch(.*)*',
      name: "Not Found",
      component: ErrorPage,
      props: {
          errorcode: '404',
          errormessage: 'Ooops, seems you got lost.',
          linkback:'/',
          linkbackmessage:'Click here to return home.'
      }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior () {
    return { behavior: 'smooth', x: 0, y: 0 }
  }
});

export default router;
