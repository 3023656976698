<template>
  <div class="menubar-contain">
    <div class="menubar" :class="{ active: isOpen }">
      <h1>{{ pageName }}</h1>
      <div class="burger" @click="toggleExploreMenu()">
        <i></i
        ><span
          ><span><span>XPLORE</span></span></span
        >
      </div>
      <div class="exploremenu text-center">
        <div class="close" @click="closeExploreMenu()"><i>✕</i></div>
        <span>{{wordingData.GalleryNamingPlural.toUpperCase()}}</span>
        <router-link
          :to="'/gallery/'+item.url"
          @click="closeExploreMenu()"
          v-for="(item, key) in galleries"
          :key="key"
          >{{ item.title }}</router-link
        >
        <span class="mb-5"></span>
        <a @click="aboutClick()">About</a>
        <a :href="contactData.contactme" v-if="contactData.contactme" target="_blank">Contact Me</a>
        <a :href="contactData.supportme" v-if="contactData.supportme" target="_blank">Support Me</a>
        <a :href="contactData.newsletter" v-if="contactData.newsletter" target="_blank">Newsletter</a>
      </div>
    </div>
    <about v-if="modalActive" @modalClose="closeModal()"></about>
  </div>
</template>

<script>
import About from "@/components/About.vue";
export default {
  name: "Menubar",
  components: {
    About,
  },
  props: {
    pageName: String,
    galleries: Array,
  },
  data: function () {
    return {
      isOpen: false,
      modalActive: false,
      wordingData: this.$store.state.wordingData,
      contactData: this.$store.state.contactData
    };
  },
  methods: {
    toggleExploreMenu() {
      this.isOpen = !this.isOpen;
    },
    closeExploreMenu() {
      this.isOpen = false;
    },
    aboutClick() {
      this.modalActive = true;
    },
    closeModal() {
      this.modalActive = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_imports.scss";
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_media.scss";

.menubar-contain {
  height: 69px;
  position: fixed;
  z-index: 100;
  width: 100%;
  top: 0;
  left: 0;
}
.menubar {
  height: 69px;
  background-color: $black;
  padding: 15px 25px;
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
}
.menubar > h1 {
  color: $white;
  font-family: $serif-font;
  font-size: 1.8rem;
  font-weight: 600;
  flex-grow: 1;
  height: 25px;
  line-height: 25px;
  margin-bottom: 0;
  text-transform: lowercase;
}

@mixin burgerbarcmn {
  display: block;
  width: 25px;
  height: 5px;
  background-color: $white;
}

@mixin menubartransition {
  transition: all 0.3s;
}

@mixin menubartransition2 {
  transition: all 0.3s 0.3s;
}

.burger {
  position: absolute;
  right: 25px;
  top: 20px;
  width: 25px;
  height: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 30003;
  @include menubartransition();
  cursor: default;
}

.burger > span {
  display: block;
  position: absolute;
  left: 30px;
  top: 0;
  height: 25px;
  line-height: 25px;
  overflow: hidden;
}

.burger > span > span {
  display: block;
  position: relative;
  right: 200%;
  top: 0;
  line-height: 25px;
  @include menubartransition();
}

.burger > span > span > span {
  color: $white;
  font-family: $sans-serif-font;
  font-size: 24px;
  font-weight: 400;
  line-height: 25px;
  transition: all 0.3s;
}

.burger > i {
  @include burgerbarcmn();
  @include menubartransition();
}

.burger:before {
  @include burgerbarcmn();
  content: "";
  position: absolute;
  top: 0;
  @include menubartransition();
}

.burger:after {
  @include burgerbarcmn();
  content: "";
  position: absolute;
  bottom: 0;
  @include menubartransition();
}

.exploremenu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100vh;
  min-height: -webkit-fill-available;
  right: -100%;
  top: 0;
  background-color: $darkblue;
  @include menubartransition();
}

.exploremenu span {
  font-family: $serif-font;
  color: $gold;
  font-size: 1.5rem;
  line-height: 2.4rem;
  font-weight: 500;
}

.exploremenu a {
  font-family: $sans-serif-font;
  color: $white;
  font-size: 1.5rem;
  line-height: 2.4rem;
  transition: all 0.3s;
}

.exploremenu a:hover {
  color: $lightgold !important;
  text-decoration: none;
  cursor: pointer !important;
}

@media #{$notphone} {
  .burger:hover {
    cursor: default;
  }
  .burger:hover > span > span > span {
    color: $gold;
    cursor: default;
  }

  .burger:hover > i,
  .burger:hover:before,
  .burger:hover:after {
    background-color: $gold;
    cursor: default;
  }
}

.menubar.active .burger {
  right: 50%;
  margin-right: 40px;
}

.menubar.active .burger > span > span {
  right: 0;
  @include menubartransition2();
}

.menubar.active .exploremenu {
  right: 0;
}

.menubar .close {
  position: absolute;
  right: 25px;
  top: 20px;
  width: 25px;
  height: 25px;
  line-height: 25px;
  cursor: pointer;
}

.menubar .close i {
  font-style: normal;
  color: $white;
  font-family: $sans-serif-font;
  font-weight: 100;
  font-size: 1.4rem;
}
</style>
