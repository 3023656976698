<template>
  <div class="loader">
    <div class="loading">
      <div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/_imports.scss";
@import "@/assets/scss/_variables.scss";
@keyframes loading {
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg) }
}
.loading > div > div {
  position: absolute;
  border-radius: 50%;
}
.loading > div > div:nth-child(1) {
  top: 30px;
  left: 30px;
  width: 140px;
  height: 140px;
  // background: #9a831c;
  border: solid 4px #9a831c73;
}
.loading > div > div:nth-child(2) {
  top: 42px;
  left: 81px;
  width: 42px;
  height: 42px;
  background: #9a831c;
  animation: loading 1s linear infinite;
  transform-origin: 21px 60.199999999999996px;
}
.loader {
  width: 200px;
  height: 200px;
  display: block;
  overflow: hidden;
  background: none;
  margin-left: auto;
  margin-right: auto;
}
.loading {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(.5);
  backface-visibility: hidden;
  transform-origin: 100px 100px;
}
.loading div { box-sizing: content-box; }
</style>
