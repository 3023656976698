<template>
  <Page hasmenubar>
    <loader v-if="loading"></loader>
    <Masonrylist :masonryItems="galleryItems" v-if="!loading && galleryItems"></Masonrylist>
    <transition name="slide-up">
      <WelcomeOverlay v-if="welcomeOverlayState" :loading="loading"></WelcomeOverlay>
    </transition>
  </Page>
</template>

<script>
import Masonrylist from "@/components/Masonrylist.vue";
import Page from "@/components/Page.vue";
import WelcomeOverlay from "@/components/WelcomeOverlay.vue";

export default {
  components: {
    Masonrylist,
    Page,
    WelcomeOverlay,
  },
  props: {
    welcomeOverlay: Boolean,
  },
  data: function () {
    return {
      apikey: process.env.VUE_APP_PUBLIC_API,
      siteurl: process.env.VUE_APP_API_URL,
      loading: false,
      galleryItems: null,
      welcomeOverlayState: this.welcomeOverlay,
      galleries: this.$store.state.galleries,
      articles: this.$store.state.articles,
    };
  },
  methods: {
    async fetch() {
      this.loading = true;
      
      // Get articles from gallery
      let res = null;
      let siteurl = process.env.VUE_APP_API_URL;
      let galleryurl = this.$route.params.gallery_url;

      let getArticles = siteurl + "/backend/api/collections/get/Article?token=" + this.apikey;

      // If main gallery
      if (galleryurl == 'main' || !galleryurl) {
        galleryurl = 'main' // if galleryurl is undefined, then set to main
        try {
          this.axios({
            method: "post",
            url: getArticles,
            data: {
              filter: {
                published: true,
                display_main_hall: true,
              },
              sort: {
                order: 1,
              },
              populate: 1,
            },
          }).then((response) => {
            res = response.data;
            this.galleryItems = res.entries;
          });
        } catch (e) {
          this.loading = false;
        } finally {
          this.loading = false;
        }
      } else {
        try {
          let findgallery = this.galleries.filter(function (e) {
            return e.url == galleryurl;
          });
          if(findgallery.length==0) {
            this.$router.push({ path: "/missing" });
          } else {
            let gallerykey = findgallery[0]._id;

            this.axios({
              method: "post",
              url: getArticles,
              data: {
                filter: {
                  published: true,
                  gallery: gallerykey,
                },
                sort: {
                  order: 1,
                },
                populate: 1,
              },
            }).then((response) => {
              res = response.data;
              this.galleryItems = res.entries;
            });
          }
        } catch (e) {
          this.loading = false;
        } finally {
          this.loading = false;
        }
      }
    },
    onScroll() {
      if(!this.loading){
        this.welcomeOverlayState = false;
        window.removeEventListener("wheel", this.onScroll);
        window.removeEventListener("touchmove", this.onScroll);
      }
    },
  },
  mounted() {
    this.fetch();
    window.addEventListener("wheel", this.onScroll);
    window.addEventListener("touchmove", this.onScroll);
  },
  watch: {
    $route: "fetch",
  },
};
</script>
