import "@babel/polyfill";
import "mutationobserver-shim";
import { createApp } from "vue";
import App from "./App.vue";
import axios from "axios";
import VueAxios from "vue-axios";
import "@/assets/scss/_imports.scss";
import "@/assets/scss/_variables.scss";
import "@/assets/scss/main.scss";
import router from "./router";
import "/node_modules/bootstrap/dist/css/bootstrap.min.css";
import mitt from "mitt"; // requirement for vue-masonry
import { VueMasonryPlugin } from "vue-masonry/src/masonry-vue3.plugin";
import store from "./store";

const emitter = mitt();

const app = createApp(App).use(store).use(router).use(VueAxios, axios);
app.config.globalProperties.emitter = emitter;
app.use(VueMasonryPlugin);
app.mount("#app");
