<template>
  <div class="navtree-contain">
    <div class="d-flex flex-column">
      <div class="d-flex justify-content-center">
        <span class="invitation">{{ invitationText }}</span>
      </div>
      <div class="d-flex justify-content-center">
        <galleryNameBox
          :galleryName="galleryName"
          :galleryNameBold="galleryNameBold"
          :galleryUrl="galleryUrl"
        ></galleryNameBox>
      </div>
      <div class="d-flex justify-content-center">
        <div class="navtree d-flex flex-column">
          <div class="d-flex justify-content-center">
            <div class="navtree-head">EXPLORE</div>
          </div>
          <div class="navtree-body d-flex justify-content-center">
            <div class="navtree-left d-flex flex-column text-right">
              <span>{{wordingData.GalleryNamingPlural.toUpperCase()}}</span>
              <router-link
                :to="'/gallery/' + item.url"
                v-for="(item, key) in galleries"
                :key="key"
                >{{ item.title }}</router-link
              >
            </div>
            <div class="navtree-trunk"></div>
            <div class="navtree-right d-flex flex-column text-left">
              <a @click="aboutClick()">About</a>
              <a :href="contactData.email" v-if="contactData.email" target="_blank">Email</a>
              <a :href="contactData.twitter" v-if="contactData.twitter" target="_blank">Twitter</a>
              <a :href="contactData.instagram" v-if="contactData.instagram" target="_blank">Instagram</a>
              <a :href="contactData.newsletter" v-if="contactData.newsletter" target="_blank">Newsletter</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <about v-if="modalActive" @modalClose="closeModal()"></about>
  </div>
</template>

<script>
import GalleryNameBox from "@/components/GalleryNameBox.vue";
import About from "@/components/About.vue";
export default {
  name: "Navtree",
  components: {
    GalleryNameBox,
    About,
  },
  props: {
    galleryName: String,
    galleryNameBold: String,
    galleryUrl: String,
    invitationText: String,
    galleries: Array
  },
  data: function () {
    return {
      modalActive: false,
      wordingData: this.$store.state.wordingData,
      contactData: this.$store.state.contactData
    };
  },
  methods: {
    aboutClick() {
      this.modalActive = true;
    },
    closeModal() {
      this.modalActive = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_imports.scss";
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_media.scss";

.navtree-contain {
  padding: 4rem 0 0 0;
  .invitation {
    padding: 1rem 0 4rem 0;
  }
}

.navtree {
  width: 420px;
  .navtree-head {
    width: auto;
    padding: 0.5rem 2rem;
    border: solid 1px #ffffff;
  }
  .navtree-body {
    .navtree-trunk {
      background-color: #ffffff;
      width: 1px;
    }
    .navtree-left,
    .navtree-right {
      width: 50%;
      padding: 3rem 1rem 2rem 1rem;
    }
    .navtree-left span,
    .navtree-right span {
      font-family: $serif-font;
      color: $gold;
      font-size: 0.8rem;
      line-height: 1.4rem;
      font-weight: 500;
    }

    .navtree-left a,
    .navtree-right a {
      font-family: $sans-serif-font;
      color: $white;
      font-size: 1rem;
      line-height: 1.4rem;
      transition: all 0.3s;
    }

    .navtree-left a:hover,
    .navtree-right a:hover {
      color: $lightgold !important;
      text-decoration: none;
    }
  }
}
</style>
