<template>
  <div class="d-flex flex-column justify-content-center h-100 homepanel" id="homepanel">
    <div class="d-flex justify-content-center">
      <galleryNameBox
        galleryName="Welcome to"
        galleryNameBold="The Galleries"
      ></galleryNameBox>
    </div>
    <div class="d-flex justify-content-center">
      <div class="gallerydescbox text-center" v-if="!loading">
        <p class="gallerydesc">
          This is a space for exploration.<br />
          Lose yourself, enjoy the ride, and<br />
          <b>
            <router-link class="text-link" to="/gallery/main">
              scroll down.
            </router-link>
          </b>
        </p>
      </div>
      <div class="gallerydescbox text-center" v-else>
        <p class="gallerydesc">
          <loader></loader>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import GalleryNameBox from "@/components/GalleryNameBox.vue";
import Loader from "@/components/Loader.vue";
export default {
  components: {
    GalleryNameBox,
    Loader,
  },
  props: {
    loading: Boolean,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_imports.scss";
@import "@/assets/scss/_variables.scss";
.homepanel {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $black;
}
</style>
