<template>
  <loader v-if="initLoading"></loader>
  <router-view v-slot="{ Component }" v-else>
    <component :is="Component" />
  </router-view>
</template>

<script>
import Loader from "@/components/Loader.vue";
export default {
  components: {
    Loader,
  },
  data: function () {
    return {
      apikey: process.env.VUE_APP_PUBLIC_API,
      initLoading: true,
      galleries: null,
      siteurl: process.env.VUE_APP_API_URL,
      uploadsurl: process.env.VUE_APP_API_URL+"/backend/storage/uploads",
    };
  },
  methods: {
    async preload() {
      let res = null;
      try {
        this.initLoading = true;
        // get galleries
        let url = this.siteurl + "/backend/api/collections/get/Gallery?token=" + this.apikey;
        await this.axios.get(url).then((response) => {
          res = response.data;
          res = res.entries.filter((datum) => { return datum.published });
          this.$store.commit('setGalleries', res)
        });
        // get articles
        url = this.siteurl + "/backend/api/collections/get/Article?token=" + this.apikey;
        await this.axios.get(url).then((response) => {
          res = response.data;
          res = res.entries.filter((datum) => { return datum.published });
          this.$store.commit('setArticles', res)
        });
        // get about data
        url = this.siteurl + "/backend/api/singletons/get/About?token=" + this.apikey;
        await this.axios.get(url).then((response) => {
          res = response.data;
          this.$store.commit('setAboutdata', res)
        });
        // get wording data
        url = this.siteurl + "/backend/api/singletons/get/Wording?token=" + this.apikey;
        await this.axios.get(url).then((response) => {
          res = response.data;
          this.$store.commit('setWordingdata', res)
        });
        // get contact data
        url = this.siteurl + "/backend/api/singletons/get/Contact?token=" + this.apikey;
        await this.axios.get(url).then((response) => {
          res = response.data;
          this.$store.commit('setContactdata', res)
        });
      } catch (e) {
        this.initLoading = true;
      } finally {
        this.initLoading = false;
      }
    },
  },
  mounted() {
    this.preload();
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_imports.scss";
@import "@/assets/scss/_variables.scss";
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  background-color: $black;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
