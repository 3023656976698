<template>
  <div class="modal-contain h-100">
    <div class="modal-inside">
      <div class="modal-menu">
        <div class="modal-about">
          <div class="modal-about-image"><img :src="$root.siteurl + aboutData.ProfilePicture.path" alt=""></div>
          <div class="modal-about-greet" v-html="aboutData.Greeting"></div>
          <div class="modal-about-text" v-html="aboutData.Text"></div>
        </div>
        <div class="modal-close" @click="closeModal()">×</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
  props: {
  },
  data: function () {
    return {
      aboutData: this.$store.state.aboutData
    };
  },
  methods: {
    closeModal() {
      this.$emit('modalClose');
    }
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_imports.scss";
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_media.scss";

.modal-contain {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
}

.modal-menu {
  background-color: $black;
  padding: 0 1.6rem;
}
.modal-menu > .modal-pagename {
  color: $white;
  font-family: $serif-font;
  font-size: 1.8rem;
  font-weight: 600;
  flex-grow: 1;
  height: 25px;
  line-height: 25px;
  margin-bottom: 0;
  text-transform: lowercase;
}

.modal-close {
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 25px;
  height: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 30003;
  color: $white;
  font-family: $sans-serif-font;
  font-size: 42px;
  font-weight: 400;
  line-height: 25px;
  cursor: default;
}

.modal-close:hover {
  color: $gold;
  cursor: default;
}

.modal-about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  height: 100vh;
  min-height: -webkit-fill-available;
  overflow-y: auto;

  > div {
    max-width: 420px;
    width: auto;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .modal-about-image {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3rem;
    > img {
      max-width: 100%;
    }
  }

  .modal-about-greet {
    color: $white;
    font-family: $sans-serif-font;
    font-size: 1.8rem;
    font-weight: 900;
    margin-bottom: 1.6rem;
  }

  .modal-about-text {
    color: $white;
    font-family: $sans-serif-font;
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-weight: 300;
  }
}
</style>
