<template>
  <div class="article-body" v-html="content">
  </div>
</template>

<script>
export default {
  props: {
    content: String,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_imports.scss";
@import "@/assets/scss/_variables.scss";
.article-body {
  padding: 50px 20px;
  ::v-deep{
    font-family: $sans-serif-font;
    color: $white;
    h2 {
      font-family: $serif-font;
      color: $gold;
      margin-bottom: 30px;
    }
    a {
      color: $gold;
      &:hover {
        color: $lightgold;
      }
    }
  }
}
</style>
