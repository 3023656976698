<template>
  <Page hasmenubar>
    <loader v-if="loading"></loader>
    <FullBanner :src="$root.uploadsurl + articleData.image.path" v-if="!loading"></FullBanner>
    <ArticleBody :content="articleData.body_content" v-if="!loading && articleData.body_content"></ArticleBody>
    <ArticleMasonrylist :masonryItems="articleItems" v-if="!loading && articleItems"></ArticleMasonrylist>
    <ArticleBody :content="articleData.footer_content" v-if="!loading && articleData.footer_content"></ArticleBody>
    <!-- <app-linkslist [title]="'REFERENCES'" [list]="imageRefs"></app-linkslist>
    <app-modal *ngIf="modalActive" [pageName]="imageName" [slides]="imageItems" [articleIndex]="articleIndex" (modalClose)="closeModal()"></app-modal> -->
  </Page>
</template>

<script>
import ArticleMasonrylist from "@/components/ArticleMasonrylist.vue";
import FullBanner from "@/components/FullBanner.vue";
import Page from "@/components/Page.vue";
import Loader from "@/components/Loader.vue";
import ArticleBody from "@/components/ArticleBody.vue";

export default {
  components: {
    ArticleMasonrylist,
    FullBanner,
    Page,
    Loader,
    ArticleBody,
  },
  props: {
    welcomeOverlay: Boolean,
  },
  data: function () {
    return {
      apikey: process.env.VUE_APP_PUBLIC_API,
      siteurl: process.env.VUE_APP_API_URL,
      loading: true,
      articleItems: null,
      articleData: null,
      galleries: this.$store.state.galleries,
      articles: this.$store.state.articles,
    };
  },
  methods: {
    async fetch() {
      this.loading = true;

      // Get images from article
      let res = null;
      let siteurl = process.env.VUE_APP_API_URL;
      let articleurl = this.$route.params.article_url;

      let getImages = siteurl + "/backend/api/collections/get/Image?token=" + this.apikey;

      try {
        let findarticle = this.articles.filter(function (e) {
          return e.url == articleurl;
        });
        if(this.$router.currentRoute._value.name=='Article' && findarticle.length==0) {
          this.$router.push({ path: "/missing" });
        } else {
          this.articleData = findarticle[0]
          let articlekey = this.articleData._id;

          this.axios({
            method: "post",
            url: getImages,
            data: {
              filter: {
                published: true,
                article: articlekey,
              },
              sort: {
                order: 1,
              },
              populate: 1,
            },
          }).then((response) => {
            res = response.data;
            this.articleItems = res.entries;
          });
        }
      } catch (e) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.fetch();
  },
  watch: {
    $route: "fetch",
  },
};
</script>