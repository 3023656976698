<template>
  <Page>
    <div class="errorbox">
      <b>{{ errorcode }}</b>
      <p>{{ errormessage }}</p>
      <router-link :to="linkback" >{{ linkbackmessage }}</router-link>
    </div>
  </Page>
</template>

<script>
import Page from "@/components/Page.vue";
export default {
  components: {
    Page,
  },
  props: {
      errorcode: {
          type: String,
          default: '404',
          required: false,
      },
      errormessage: {
          type: String,
          default: 'Ooops, seems you got lost.',
          required: false,
      },
      linkback: {
          type: String,
          default: '/',
          required: false,
      },
      linkbackmessage: {
          type: String,
          default: 'Click here to return home.',
          required: false,
      },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_imports.scss";
@import "@/assets/scss/_variables.scss";

.errorbox {
  margin: 30px auto;
  border: solid 1px $lightgold;
  padding: 100px 30px;
  width: 90%;
  max-width: 500px;
  text-align: center;
  b {
    font-size: 4rem;
    color: $gold;
  }
  p {
    font-size: 1.2rem;
    color: $white;
  }
  a {
    font-size: 1rem;
    color: $lightgold;
    text-decoration: none;
    &:hover {
      color: $gold;
    }
  }
}
</style>